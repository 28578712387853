export function useWidgetCloudflareTurnstile() {
  const turnstileWidgetId = useState('turnstileWidgetId', () => null);
  const turnstileToken = useState('turnstileToken', () => null);
  const isWidgetResetted = useState('isWidgetResetted', () => false);

  function resetTurnstileWidget() {
    turnstile.reset(turnstileWidgetId.value);
    isWidgetResetted.value = true;
  }

  function updateTurnstileToken() {
    if (isWidgetResetted.value) {
      turnstileToken.value = turnstile.getResponse(turnstileWidgetId.value);
      isWidgetResetted.value = false;
    }
  }

  return {
    turnstileWidgetId,
    turnstileToken,
    isWidgetResetted,
    resetTurnstileWidget,
    updateTurnstileToken,
  };
}
